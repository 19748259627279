<script>
/**
 * LOAD DEPENDENCIES
 * --------------------------------------------------------------------------------
 */
import {ref, computed} from "vue";
import {useStore} from "vuex";
import {useRouter, useRoute} from "vue-router";

/**
 * VUE COMPONENT
 * --------------------------------------------------------------------------------
 */
export default {
  setup(){
    const store     = useStore();
    const route     = useRoute();
    const router    = useRouter();
    
    const item = computed( () => {
      return store.state.publications.find(d => d.id == route.params.id && d.country == store.state.token_country);
    });

    if(!item.value){
      router.push({name :"404"});
    }

    console.log("item:", item);

    const id          = item.value.id;
    const title       = ref(item.value.title);
    const description = ref(item.value.description);
    const category    = ref(item.value.category);
    const author      = ref(item.value.author);
    const publication = ref(null);
    const date        = ref(item.value.date);
    const cover       = ref(null);
    const showSuccess = ref(null);
    const showError   = ref(null);

    // const clearForm = () => {
    //   title.value             = "";
    //   description.value       = "";
    //   category.value          = "";
    //   author.value            = "";
    //   publication.value.value = "";
    //   date.value              = "";
    //   cover.value.value       = "";
    // }

    const makeObject = () => {
      const formData = new FormData();
      formData.append("id", id);
      formData.append("title", title.value);
      formData.append("description", description.value);
      formData.append("category", category.value);
      formData.append("author", author.value);
      formData.append("date", date.value);
      formData.append("publication", publication.value.files[0]);
      formData.append("cover", cover.value.files[0]);

      return formData;
    }

    const updatePublication = () => {
      const obj = makeObject();
      store.dispatch("updatePublication", obj)
      .then( () => {
        showSuccess.value = true;
        // clearForm();
        setTimeout(() => showSuccess.value = null, 5000)
      })
      .catch( () => {
        showError.value = true;
        setTimeout(() => showError.value = null, 5000)
      });
    }

    return {
      title,
      description,
      category,
      author,
      publication,
      date,
      cover,

      updatePublication,
      showSuccess,
      showError
    }
  }
  
}
</script>
<template>
  <form v-on:submit.prevent="updatePublication">
    <transition name="fade">
      <p v-if="showSuccess" class="ob_alert success">la publicación se ha guardado</p>
    </transition>

    <transition name="fade">
      <p v-if="showError" class="ob_alert danger">la publicación no se pudo guardar</p>
    </transition>

    <p>
      <label>título*:</label>
      <input type="text" required v-model="title" />
    </p>
    <p>
      <label>descripción*:</label>
      <textarea required v-model="description"></textarea>
    </p>
    <div class="row">
      <div class="col-sm-9">
    <p>
      <label>categoría:</label>
      <input type="text" v-model="category" />
    </p>
      </div>
      <div class="col-sm-3">
    <p>
      <label>fecha de publicación*:</label>
      <input type="date" required v-model="date" />
    </p>
      </div>
    </div>
    <p>
      <label>autor(es)*:</label>
      <input type="text" required v-model="author" />
    </p>
    <p>
      <label>publicación*:</label>
      <input ref="publication" type="file" />
    </p>
    <p>
      <label>portada:</label>
      <input ref="cover" type="file" />
    </p>
    
    <p>
      <input type="submit" value="Guardar" />
    </p>
  </form>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>